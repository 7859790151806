import { React, useState, useEffect } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux';
import { ssoAuthetication } from '../../../appStore/thunks/ssoAuthetication';
import { setAgentAuthetication, setAgentToken,setOrgId } from "../../../appStore/actionCreators/agent";
import  SearchFormContainerNew  from "./SearchFormContainerNew"
import {setSearchStatus} from "../../../appStore/actionCreators/flightBooking"
import { useHistory, useLocation } from "react-router-dom";
import { setFlightType, setShowAgencyInfo ,setUrlUsd } from '../../../appStore/actionCreators/searchForm';
import {resetAppStore} from '../../../appStore/resetAppStore';
import { env } from '../../../appConfig';

function SsoAuthContainer(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const status = useSelector((state) => state.agentProfile.isAgentAutheticated);
    const [isRsult, setResult] = useState(undefined)
    const [newToken,setTokenSatus] =useState("")

	const [tokenPresent, setTokenPresent] = useState(false)
    const token = useSelector((state) => state.agentProfile.agentToken);
	const newUser = newToken?.includes("XSPlus") || token?.includes("XSPlus");
	const language = useSelector( (state) => state.lang.lang)
    const agentToken = useSelector((state) => state.agentProfile.agentToken);
    const orgId = useSelector((state) => state.agentProfile.orgId);
    const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
    // const internalUserAccessToken = useSelector((state)=> state.internalUser.internalUserAccessToken);

    const value = queryString.parse(props.location && props.location.search);
    const tokenParam = value.token;
    const orgIdParam = value.orgId;
    const user = value.user;
    const flightType = value.flightType;
    console.log("flightType",flightType)

    useEffect(() => { 

        if (tokenParam && Object.keys(value).length>0) {
            dispatch({ type: "RESET_EVERYTHING" });
            dispatch(setAgentToken(""))
            dispatch(setAgentAuthetication(undefined));
            dispatch(setAgentToken(tokenParam))
            dispatch(setOrgId(orgIdParam));
            setTokenSatus(tokenParam)
        } else if (status !== undefined && status === false) {
            if(agentToken.length===0 && value && Object.keys(value).length===0){
            window.location.href = env.TRAVELBRANDS_AGENT_URL
            }
        }
        
        dispatch(setSearchStatus("searchWidget"))
        if(flightType !== undefined){
            window.selectedDollarValue = flightType.toLowerCase() === "usd" ? "$" : "$"
            dispatch(setFlightType(flightType));
            dispatch(setUrlUsd(`/?user=internal&flightType=${flightType}`));  
        }
        else{
            window.selectedDollarValue = "$"
        }
    }, []) 


    // HEADER AND FOOTER FOR EXTERNAL USER
    function addNewRemoveHeaderFooter(location,token,language){
        const isHeaderFooterAvailable = document.querySelector("#traveBrandHeader");
        const isHeaderFooterAvail = document.querySelector(".travelbrands-header");
        if(location.pathname !== "/login" && !isHeaderFooterAvailable && !isHeaderFooterAvail){
            const elBody = document.getElementsByTagName('body');
            var xhr= new XMLHttpRequest();
            xhr.open('GET', `${env.REACT_APP_NEW_HEADER_URL}?token=${token}&lang=${language}`,true);
            xhr.onreadystatechange = function() {
            if (this.readyState!==4) return;
            if (this.status!==200) return;
            elBody[0].insertAdjacentHTML('afterbegin', this.responseText);
            };
            xhr.send();
            // FOOTER CODE
            var xhr= new XMLHttpRequest();
            xhr.open('GET', `${env.REACT_APP_NEW_FOOTER_URL}?lang=${language}&token=${token}`,true);
            xhr.onreadystatechange = function() {
            if (this.readyState!==4) return;
            if (this.status!==200) return;
            elBody[0].insertAdjacentHTML('beforeend', this.responseText);
            };
            xhr.send();
        } else if(location.pathname === "/login" && (isHeaderFooterAvailable || isHeaderFooterAvail)){
            const head = document.querySelector("head");
            const body = document.querySelector("body");
            const header = document.querySelector("#hdrWrap");
            isHeaderFooterAvailable && head.removeChild(isHeaderFooterAvailable);
            isHeaderFooterAvail && head.removeChild(isHeaderFooterAvail);
            if(header){
                const topImg = document.querySelector("#topImg");
                const footerWrapper = document.querySelector("#footerWrapper");
                header && body.removeChild(header);
                topImg && body.removeChild(topImg);
                footerWrapper && body.removeChild(footerWrapper);
            }
            window.location.reload()
        }
    }
    
    function addRemoveHeaderFooter(location, isInternalUser,language){
            const isHeaderFooterAvailable = document.querySelector("#traveBrandHeader");
            const isNewHeader = document.querySelector(".main-header");	
            const headerURL = language === "fr" ? env.REACT_APP_HEADER_URL_FRENCH : env.REACT_APP_HEADER_URL;
            const footerURL = language === "fr" ? env.REACT_APP_FOOTER_URL_FRENCH : env.REACT_APP_FOOTER_URL;
            if(location.pathname !== "/login" && !isHeaderFooterAvailable && !isNewHeader){
                const script = document.createElement('script');
                script.setAttribute('src', headerURL);
                script.setAttribute('id', "traveBrandHeader");
                
                script.setAttribute('async', '');
                script.onload = function handleScriptLoaded() {
                console.log('script has loaded');
                // document.getElementById('box')?.textContent = 'Script loaded successfully';
                };
    
                script.onerror = function handleScriptError() {
                    console.log('error loading script');
                };
    
                document.head.appendChild(script);
    
                // FOOTER CODE
                const elBody = document.getElementsByTagName('body');
                var xhr= new XMLHttpRequest();
                xhr.open('GET', footerURL,true);
                xhr.onreadystatechange = function() {
                if (this.readyState!==4) return;
                if (this.status!==200) return;
                    elBody[0].insertAdjacentHTML('beforeend', this.responseText);
                };
                xhr.send();
                if(isInternalUser){
                    let time = 0;
                    let tOut = setInterval(() => {
                        const parent = document.querySelector("#subNav");
                        const gearIcon = document.querySelector("#gear");
                        if(parent && gearIcon){
                            parent.removeChild(gearIcon);
                            clearTimeout(tOut);
                        } else if(time <= 9){
                            time += 1;
                        }else{
                            clearTimeout(tOut);
                        }
                    }, 100);
                }
            } else if(location.pathname === "/login" && isHeaderFooterAvailable){
                const head = document.querySelector("head");
                const body = document.querySelector("body");
                const header = document.querySelector("#hdrWrap");
                head.removeChild(isHeaderFooterAvailable);
                if(header){
                    const topImg = document.querySelector("#topImg");
                    const footerWrapper = document.querySelector("#footerWrapper");
                    header && body.removeChild(header);
                    topImg && body.removeChild(topImg);
                    footerWrapper && body.removeChild(footerWrapper);
                }
                window.location.reload()
            }
    }

	useEffect(() => {
		if(isRsult){
		if (newUser == true || tokenPresent) {
        if (token && newUser){
            addNewRemoveHeaderFooter(location, token,language)
        }
		} else {
			addRemoveHeaderFooter(location, isInternalUserAutheticated,language);
		}
	   }
	}, [isRsult]);

	useEffect(() => {
		const newUser = token?.includes("XSPlus");
		if (newUser == true) {
			if (token) {
				setTokenPresent(true)
			}
		} else {
			setTokenPresent(false)
		}
	}, [token])
// HEADER AND FOOTER FOR EXTERNAL USER END
    useEffect(async () => {
        if(orgId && newToken.length>0){
            let updatedToken = ""
            if(agentToken===newToken || newToken.length===0){
                updatedToken =agentToken
            } else if(newToken.length>0){
                updatedToken = newToken
            } 
            let seoResult = await dispatch(ssoAuthetication(orgId, newToken))

            if (
                seoResult &&
                seoResult.hasOwnProperty("travelBusinessesId")
            ) {
                setResult(true)
                history.push('/') 
            } else {
                setResult(false)
            }
        } 
    }, [newToken,orgId]);

    const getRedirectExternalUserLogin = () => {
        if (window.location.pathname !== '/SearchResults' && window.location.pathname !== "/SearchResultsFlexiDate") {
            if(newToken?.includes("XSPlus")){
                window.location.href = env.TBIDEVELOPMENT_URL
            }else{
                window.location.href = env.TRAVELBRANDS_AGENT_URL
            }       
        }
    }

    const getRedirect = () => {
        history.push("/login") 
        resetAppStore();
        dispatch(setShowAgencyInfo(true));       
    }

    useEffect(() => {        
       if(isInternalUserAutheticated === true) {          
            dispatch(setShowAgencyInfo(true)); 
        } 
    }, [])

    

    // window.onunload = () => {
    //     // Clear the local storage
    //     window.MyStorage.clear()
    //  }


    return (
        <>

            {
                user === undefined && !status && tokenParam === undefined && orgIdParam === undefined ?
                    getRedirect() :
                    user === 'internal' && tokenParam === undefined && orgIdParam === undefined && !isInternalUserAutheticated
                    ?
                        getRedirect() :
                        user === 'internal' && tokenParam === undefined && orgIdParam === undefined && isInternalUserAutheticated
                        ?
                        <SearchFormContainerNew showAgent="true"/>  
                        : 
               
                status === undefined 
                ? null 
                :
                    status === true ?
                        <SearchFormContainerNew /> 
                        :
                        (status === false && isRsult === false) ? 
                            getRedirectExternalUserLogin() 
                            : null

              
            }
        </>
    );
}

export default SsoAuthContainer;
